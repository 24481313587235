
import { defineComponent, ref, onBeforeUnmount, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";
import UploadLegacyPolicyFileError from "@/components/modals/UploadLegacyPolicyFileError.vue";

export default defineComponent({
  name: "admin-legacy-policies-bulk-upload-list",
  components: { UploadLegacyPolicyFileError },
  setup() {
    const {
      isEmptyArray,
      displayDatatable,
      reinitialization,
    } = ResusableFunctions();
    const timeLeft = ref<number>(0);
    let countdownInterval: ReturnType<typeof setInterval> | null = null;

    const policies = ref([
      {
        businessClass: "",
        uploadSize: 0,
        uploadedBy: { firstName: "", lastName: "" },
        status: "",
        publicId: "",
        created: "",
        wasSuccessful: false,
        createdOn: "",
        failedUploads: [],
      },
    ]);

    const errors = ref([] as any);

    const getPolicies = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_POLICY_UPLOAD)
          .then(({ data }) => {
            //Assign data to form fields
            policies.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    // Refresh the list after 5 seconds
    setTimeout(() => {
      getPolicies();
    }, 5000);

    const viewErrors = (details) => {
      errors.value = details;
    };

    const startCountdown = (seconds: number) => {
      timeLeft.value = seconds;

      // Update the time left every second
      countdownInterval = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value--;
        } else {
          // Stop the countdown when it reaches zero
          clearInterval(countdownInterval!);
        }
      }, 1000);
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Uploaded Schedule", ["Policy Migration"]);
      getPolicies();
      if (!isEmptyArray(policies.value)) {
        displayDatatable(["basicExample"]);
        startCountdown(5);
      }
    });

    // Clean up the interval when the component is unmounted
    onBeforeUnmount(() => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    });

    return { reinitialization, policies, viewErrors, errors, timeLeft };
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Uploaded Schedule List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
